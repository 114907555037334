<template>
  <v-app>

    <!-- Navigation Drawer -->
    <v-navigation-drawer v-model="drawer" app class="px-5 d-print-none elevation-0" height="100%" colors="#444444"
      v-if="this.$store.state.ngayi.email">
      <!-- Top Section -->
      <v-list-item class="pt-5 text-center">
        <v-list-item-avatar>
          <v-img :src="require('@/images/11.png')" />
        </v-list-item-avatar>
      </v-list-item>
      <v-divider></v-divider>

      <!-- Middle Section -->
      <v-list dense>
        <v-subheader class="ml-2 font-weight-bold">Menu Principal</v-subheader>
        <v-list-item link v-for="(option, index) in options" :key="index" @click="destination(option.lien)">
          <v-list-item-content>
            <v-list-item-title>
              <i :class="'mr-2 fas '+option.icon"></i>
              <span>{{ option.nom }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <!-- Bottom Section -->
      <v-list dense>
        <v-subheader class="ml-2 font-weight-bold">Autre Menu</v-subheader>
        <!-- <v-list-item @click="destination('/user/documents')">
          <v-list-item-content>
            <v-list-item-title>
              <i class="mr-2 fas fa-bars"></i>
              <span style="font-size:1em;">Document</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item @click="destination('/user/parametres')">
          <v-list-item-content>
            <v-list-item-title>
              <i class="mr-2 fas fa-bars"></i>
              <span style="font-size:1em;">Paramètres</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <i class="mr-2 fas fa-bars"></i>
              <span style="font-size:1em;">Aide</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item @click="Deconnexion()">
          <v-list-item-content>
            <v-list-item-title>
              <i class="mr-2 fas fa-bars"></i>
              <span style="font-size:1em;">Deconnexion</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="d-flex align-items-centers d-print-none d-none">

      <!-- App Bar -->
      <v-app-bar app class="px-5 elevation-0 bg-program" v-if="this.$store.state.ngayi.email">
        <!-- App Name (on the left side) -->
        <v-toolbar-title class="text-h5">
          <header id="header" class="header d-flex align-items-center sticky-top py-2 m-0 bg-transparent">

            <div class="container-fluid container-xl position-relative d-flex align-items-center">

              <a href="/" class="logo d-flex align-items-center me-auto">
                <!-- Uncomment the line below if you also wish to use an image logo -->
                <!-- <img src="assets/img/logo.png" alt=""> -->
                <h1 class="sitename" style="color: white !important;">Mosi</h1>
              </a>

            </div>

          </header>


          <v-btn icon @click.stop="backHistory()" v-if="showBtn">
            <i class="fas fa-arrow-left"></i>
          </v-btn>
        </v-toolbar-title>


        <!-- User Avatar (on the right side) -->
        <v-btn icon>
          <v-avatar>
            <img :src="userAvatar" class="img-fluid" alt="User Avatar">
          </v-avatar>
        </v-btn>
        <span class="mx-2 d-none d-md-block">{{ userName }}</span>

        <!-- Notification Button (on the right side) -->
        <v-btn text icon>
          <v-icon class="fas fa-bell"></v-icon>
          <v-badge color="red" :content="notificationCount"></v-badge>
        </v-btn>

        <!-- Open Drawer on Mobile -->
        <v-btn icon @click.stop="drawer = !drawer">
          <i class="fas fa-bars"></i>
        </v-btn>
      </v-app-bar>

      <!-- Main Pages -->
      <!-- <div class="w-100" style="background-color: rgba(0, 0, 0, 1) !important"> -->
      <div class="w-100" style="background-color: rgba(92, 142, 176, .1) !important">
        <router-view />
      </div>

    </v-main>

    <div v-if="isBulletinPage" class="d-none d-print-block bg-white m-0 p-0">
      <router-view />
    </div>


    <!-- dialogInsert -->
    <v-snackbar v-model="$store.state.dialogInsert" :timeout="timeout">
      <h5>{{ dialogMessage }}</h5>

      <template v-slot:actions>
        <v-btn color="pink" variant="text" @click="close()">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog for login failed message -->
    <v-dialog v-model="$store.state.dialogWait" max-width="400" persistent>
      <v-card class="py-5">
        <v-card-title class="headline text-center">Connection en cours...</v-card-title>
        <v-card-text class="text-center">
          <v-progress-circular indeterminate :size="50"></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
  // import InfoEtablissement from "./components/Users/Etablissements/Includes/ProfilePart.vue";

  import {
    mapGetters
  } from 'vuex';
  import {
    Utilisateur
  } from './classes/Utilisateur';

  export default {
    el: '#app',
    name: 'App',
    data() {
      return {
        drawer: true,
        dialogWait: false,
        mini: false,
        activeItem: null,
        snackbar: true,
        timeout: 5000,
        // options: [],
        items: [{
            icon: 'mdi-home',
            text: 'Home'
          },
          {
            icon: 'mdi-account',
            text: 'Profile'
          },
          {
            icon: 'mdi-settings',
            teaxt: 'Settings'
          },
          // Add more menu items as needed
        ]
      };
    },
    mounted() {
      // alert(process.env.VUE_APP_BASE_URL );
      const email = this.getCookie('email');
      const password = this.getCookie('psw');

      // alert(email + " -- " + password);

      // Check the cookie value and perform the redirection if needed
      if (email && password) {
        this.login(email, password);
      } else {
        this.$router.push("/login");
      }
      window.scrollTo(0, 0);
    },
    computed: {
      ...mapGetters(['getNgayi', 'getQui']),
      ngayi() {
        // Access the private variable through the getter
        return this.getNgayi;
      },
      qui() {
        return this.getQui;
      },
      isBulletinPage() {
        return this.$route.path === '/bulletin';
      },
      options() {
        return this.$store.state.menu;
      },
      // Map the getter to a local variable
      ...mapGetters(['getUtilisateur', 'getMenu',
        'getDialogMessage'
      ]),
      user() {
        // Access the private variable through the getter
        return this.getUtilisateur;
      },
      options() {
        return this.getMenu;
      },
      dialogMessage() {
        return this.getDialogMessage;
      },
    },
    components: {
      // InfoEtablissement
    },
    methods: {
      getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      },
      setCookie(name, value) {
        let expires = "";
        const date = new Date();
        date.setTime(date.getTime() + (30 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      },
      login(email, password) {
        // Simulated login logic - replace with actual authentication code
        if (email && password) {

          this.$store.state.dialogWait = true;

          try {
            this.getData(65, email, password).then(result => {

              if (result.length > 0) {
                if (result[0].result == 100) {
                  this.$store.state.dialogWait = false;
                  this.$router.push('/motdepasseoublie');
                } else {
                  var utilisateur = new Utilisateur(
                    result[0].utilisateur_id,
                    result[0].nom,
                    result[0].postnom,
                    result[0].prenom,
                    result[0].sexe,
                    result[0].email,
                    result[0].telephone,
                    result[0].nationalite,
                    result[0].image_profile,
                    result[0].date_de_naissance,
                    result[0].domicile,
                    result[0].territoire,
                    result[0].ville,
                    result[0].province
                  );

                  this.$store.commit("setNgayi", utilisateur);
                  this.$store.state.email = utilisateur.getEmail();

                  this.getLesFonctions(this.ngayi.getId());
                }

              } else {
                this.dialog = true;
                this.$store.state.dialogWait = false;
              }
            });
          } catch (error) {
            this.$store.state.dialogWait = false;
          }
        }
      },
      getLesFonctions(id) {
        this.getData(66, id).then(result => {
          if (result.length > 0) {
            this.$store.commit("setQui", result);

            const email = this.ngayi.getEmail();

            if (email) {
              // window.parent.location.href = "/home";
              this.$store.state.dialogWait = false;
              this.$router.push('/home');
              // this.$routerx.openInNewTab("/home");
            } else {
              this.dialog = true;
              this.$store.state.dialogWait = false;
            }
          } else {
            this.dialog = true;
            this.$store.state.dialogWait = false;
          }
        })

      },
      async getData(...args) {
        var result = "";
        try {
          // Call the Vuex action to fetch data
          const res = await this.$store.dispatch('getData', args);
          // Update the data with the fetched employees
          result = res;
        } catch (error) {
          // Handle any errors
          console.log('Error Login:', error);
          this.dialog = true;
        }
        return result;
      },

      Deconnexion() {
        this.$store.dispatch("setCookie", {
          name: 'email',
          value: ""
        });
        this.$store.dispatch("setCookie", {
          name: 'psw',
          value: ""
        });
        location.reload();
      },
      destination(value) {
        this.$router.push(value);
        // return "#"+value;
      },

      close() {
        // this.$store.commit("setDialogInsert", false);
        // this.$store.commit("setDialogError", false);
        // this.$store.commit("setDialogQuestion", false);
        this.$store.state.dialogInsert = false;
        this.$store.state.dialoError = false;
        this.$store.state.dialogQuestion = false;
      }
    }
  };
</script>
<style src="./main.css"></style>
<style src="./style.css"></style>