import {
  createStore
} from "vuex";
import {
  api
} from '@/ServiceApi.js';
import {
  Utilisateur
} from "@/classes/Utilisateur";

import * as XLSX from 'xlsx';

export default createStore({

  state: {

    //------------------------------------------------------------
    ngayi: new Utilisateur(),
    ye: new Utilisateur(),
    utilisateurs: [],
    qui: {},
    etablissement: {},
    menu: [],
    email: '',
    professeurs: [],
    professeursCours: [],
    cours: [],
    leCours: [],
    laClasse: {},
    lOption: -1,
    laSection: '',
    laSession: -1,
    classes: [],
    coursPredefinis: [],
    coursEtablissement: [],
    coursSection: [],
    coursOptions: [],
    coursProfesseur: [],
    schedule: [],
    horaire: [],
    documents: [],
    etudiants: [],
    appreciations: [],
    sites: [],
    sections: [],
    options: [],
    heures: [],
    vacations: [],
    dataset: [],
    enseigner: [],
    listeCotes: [],
    listePresence: [],
    identifiant: {},
    clickedPoints: [],
    info: {
      title: 'Rechercher un Etudiant',
      description: 'dsad kdjsad'
    },
    stats: [],
    stats_total: [],
    annee_id: '',
    annees: [],

    dialogError: false,
    dialogInsert: false,
    dialogQuestion: false,
    dialogWait: false,
    dialogMessage: "",
    province: "",
    search: "",
    donnee: "",
    provinces: [
          'Kinshasa',
          'Kongo Central',
          'Kwango',
          'Kwilu',
          'Mai-Ndombe',
          'Kasaï',
          'Kasaï-Central',
          'Kasaï-Oriental',
          'Lomami',
          'Sankuru',
          'Maniema',
          'Sud Kivu',
          'Nord Kivu',
          'Ituri',
          'Haut-Uele',
          'Tshopo',
          'Bas-Uele',
          'Nord-Ubangi',
          'Mongala',
          'Sud-Ubangi',
          'Équateur',
          'Tshuapa',
          'Tanganyika',
          'Haut-Lomami',
          'Lualaba',
          'Haut-Katanga'
        ],
    //------------------------------------------------------------

    show: "Main",
    history: [],
    // ye: {
    //   name: '',
    //   fullname: '',
    //   image: ''
    // },
    school: {
      name: "ISIPA"
    },
    // email: "Bongayi",
    password: "",

    result: []
  },
  getters: {
    getDataSet: state => {
      return state.dataset;
    },
    getSearch: state => {
      return state.search;
    },
    getDonnee: state => {
      return state.donnee;
    },
    getStats: state => {
      return state.stats;
    },
    getStatsTotal: state => {
      return state.stats_total;
    },
    getProvince: state => {
      return state.province;
    },
    getNgayi: state => {
      return state.ngayi;
    },
    getYe: state => {
      return state.ye;
    },
    getUtilisateurs: state => {
      return state.utilisateurs;
    },
    getQui: state => {
      return state.qui;
    },
    getEtablissement: state => {
      return state.etablissement;
    },
    getMenu: state => {
      return state.menu;
    },
    getProfesseurs: state => {
      return state.professeurs;
    },
    getProfesseursCours: state => {
      return state.professeursCours;
    },
    getCours: state => {
      return state.cours;
    },
    getLeCours: state => {
      return state.leCours;
    },
    getCoursPredefinis: state => {
      return state.coursPredefinis;
    },
    getCoursEtablissement: state => {
      return state.coursEtablissement;
    },
    getCoursSections: state => {
      return state.coursSections;
    },
    getCoursOptions: state => {
      return state.coursOptions;
    },
    getCoursProfesseur: state => {
      return state.coursProfesseur;
    },
    getEmploiDuTemps: state => {
      return state.schedule;
    },
    getHoraire: state => {
      return state.horaire;
    },
    getDocuments: state => {
      return state.documents;
    },
    getEtudiants: state => {
      return state.etudiants;
    },
    getAppreciations: state => {
      return state.appreciations;
    },
    getSections: state => {
      return state.sections;
    },
    getSites: state => {
      return state.sites;
    },
    getOptions: state => {
      return state.options;
    },
    getHeureEtudes: state => {
      return state.heures;
    },
    getVacations: state => {
      return state.vacations;
    },
    getClasses: state => {
      return state.classes;
    },
    getLaClasse: state => {
      return state.laClasse;
    },
    getDialogError: state => {
      return state.dialogError;
    },
    getLOption: state => {
      return state.lOption;
    },
    getLaSection: state => {
      return state.laSection;
    },
    getLaSession: state => {
      return state.laSession;
    },
    getDialogMessage: state => {
      return state.dialogMessage;
    },
    getEnseigner: state => {
      return state.enseigner;
    },
    getListeCotes: state => {
      return state.listeCotes;
    },
    getListePresence: state => {
      return state.listePresence;
    },
    getIdentifiant: state => {
      return state.identifiant;
    },
  },
  mutations: {
    SET_DATASET(state, dataset) {
      state.dataset = dataset;
    },
    MAKE_DATA(state, element) {
      state.dataset.push(element);
    },
    setNgayi(state, value) {
      state.ngayi = value
    },
    setProvince(state, value) {
      state.province = value
    },
    setStats(state, value) {
      state.stats = value
    },
    setStatsTotal(state, value) {
      state.stats_total = value
    },
    setYe(state, value) {
      state.ye = value
    },
    setSearch(state, value) {
      state.search = value
    },
    setDonnee(state, value) {
      state.donnee = value
    },
    setUtilisateurs(state, value) {
      state.utilisateurs = value
    },
    setQui(state, value) {
      state.qui = value
    },
    setEtablissement(state, value) {
      state.etablissement = value
    },
    setMenu(state, value) {
      state.menu = value
    },
    setProfesseurs(state, value) {
      state.professeurs = value
    },
    setProfesseursCours(state, value) {
      state.professeursCours = value
    },
    setCours(state, value) {
      state.cours = value
    },
    setLeCours(state, value) {
      state.leCours = value
    },
    setCoursEtablissement(state, value) {
      state.coursEtablissement = value
    },
    setCoursSections(state, value) {
      state.coursSections = value
    },
    setCoursOptions(state, value) {
      state.coursOptions = value
    },
    setCoursProfesseur(state, value) {
      state.coursProfesseur = value
    },
    setEmploiDuTemps(state, value) {
      state.schedule = value
    },
    setHoraire(state, value) {
      state.horaire = value
    },
    setDocuments(state, value) {
      state.documents = value
    },
    setEtudiants(state, value) {
      state.etudiants = value
    },
    setAppreciations(state, value) {
      state.appreciations = value
    },
    setCoursPredefinis(state, value) {
      state.coursPredefinis = value
    },
    setSites(state, value) {
      state.sites = value
    },
    setSections(state, value) {
      state.sections = value
    },
    setOptions(state, value) {
      state.options = value
    },
    setHeureEtudes(state, value) {
      state.heures = value
    },
    setVacations(state, value) {
      state.vacations = value
    },
    setClasses(state, value) {
      state.classes = value
    },
    setLaClasse(state, value) {
      state.laClasse = value
    },
    setDialogError(state, value) {
      state.dialogError = value
    },
    setLOption(state, value) {
      state.lOption = value
    },
    setLaSection(state, value) {
      state.laSection = value
    },
    setLaSession(state, value) {
      state.laSession = value
    },
    setDialogMessage(state, value) {
      state.dialogMessage = value
    },
    setEnseigner(state, value) {
      state.enseigner = value
    },
    setListeCotes(state, value) {
      state.listeCotes = value
    },
    setListePresence(state, value) {
      state.listePresence = value
    },
    setIdentifiant(state, value) {
      state.identifiant = value
    },
  },
  actions: {
    getData({
      commit
    }, payload) {
      // Call the fetchEmployees function to fetch data from the backend
      return api.get(payload)
        .then(result => {
          return result;
        })
        .catch(error => {
          // Handle any errors
          alert('Error fetching data:', error.message);
          throw error; // Re-throw the error to be handled by the caller
        });
    },
    setCookie({
      commit
    }, payload) {
      let expires = "";
      const date = new Date();
      date.setTime(date.getTime() + (30 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
      document.cookie = payload.name + "=" + payload.value + expires + "; path=/";
    },
    async sendData({
      commit
    }, payload) {
      try {
        // Make a POST request to the backend endpoint
        await api.post(payload).then(response => {
          return response;
        });
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async ready({
      commit,
      dispatch
    }, payload) {
      try {
        const req = payload[0];
        const coursAjoutes = payload[1];

        const arrayLength = coursAjoutes.length;
        const interval = 100;
        let counter = 0;

        const remainder = arrayLength % interval;

        if (arrayLength === remainder) {
          commit('SET_DATASET', [{
            value: req
          }]);
          for (let i = 0; i <= arrayLength - 1; i++) {
            commit('MAKE_DATA', coursAjoutes[i]);
          }

          var dataset = this.state.dataset;

          await dispatch('sendData', {
            dataset
          }); // Dispatch sendData action
        } else {
          for (let i = 0; i <= arrayLength - 1; i++) {
            counter++;
            commit('SET_DATASET', [{
              value: req
            }]);
            commit('MAKE_DATA', coursAjoutes[i]);
            if (counter === interval) {

              var dataset = this.state.dataset;

              await dispatch('sendData', {
                dataset
              }); // Dispatch sendData action
              counter = 0;
            }
          }

          if (counter === remainder) {
            commit('SET_DATASET', [{
              value: req
            }]);
            for (let i = counter; i <= remainder - 1; i++) {
              commit('MAKE_DATA', coursAjoutes[i]);
            }

            var dataset = this.state.dataset;

            await dispatch('sendData', {
              dataset
            }); // Dispatch sendData action
            commit('SET_DATASET', []);
          }
        }

        return 200;

      } catch (error) {
        alert('Error fetching data:', error.message);
        throw error;
      }
    },
    exportCSVsss({ commit }, { array, fileName }) {
      const convertToCSV = (array) => {
        const headers = Object.keys(array[0]);
        const rows = array.map(obj => headers.map(header => obj[header]));
        
        // Create CSV string with UTF-8 encoding
        return [
          headers.join(','), // Header row
          ...rows.map(row => row.join(',')) // Data rows
        ].join('\n');
      };

      // Convert array to CSV format
      const csv = convertToCSV(array);

      // Create a Blob with UTF-8 BOM
      const bom = '\uFEFF'; // Byte Order Mark for UTF-8
      const blob = new Blob([bom + csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      // Create a link and trigger download
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL after download
      URL.revokeObjectURL(url);
    },

    exportCSV({ commit }, { array, fileName }) {
      // Create a new workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(array);

      // Define the header cell style
      const headerCellStyle = {
        font: { bold: true, sz: 14 },
        alignment: { horizontal: 'center' }
      };

      // Apply styles to the header row (first row)
      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let col = headerRange.s.c; col <= headerRange.e.c; ++col) {
        const address = XLSX.utils.encode_cell({ r: 0, c: col });
        if (worksheet[address]) {
          worksheet[address].s = headerCellStyle;
        }
      }

      // Auto-fit columns based on content length
      const columnWidths = array.reduce((acc, row) => {
        Object.keys(row).forEach((key, index) => {
          const cellValue = row[key];
          const columnIndex = index;
          const cellLength = cellValue ? cellValue.toString().length : 10; // Default length
          acc[columnIndex] = Math.max(acc[columnIndex] || 0, cellLength);
        });
        return acc;
      }, []);

      worksheet['!cols'] = columnWidths.map(width => ({ width: width + 2 })); // Adding extra padding

      // Append worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // Write the workbook to binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

      // Convert binary string to Blob
      const buf = new ArrayBuffer(wbout.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < wbout.length; i++) {
        view[i] = wbout.charCodeAt(i) & 0xFF;
      }
      const blob = new Blob([buf], { type: 'application/octet-stream' });

      // Create a link and trigger download
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.style.visibility = 'hidden';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL after download
      URL.revokeObjectURL(url);
    }

    ,






    setNgayi: ({
      commit
    }, newValue) => {
      commit('setNgayi', newValue);
    },
    setYe: ({
      commit
    }, newValue) => {
      commit('setYe', newValue);
    },
    setUtilisateurs: ({
      commit
    }, newValue) => {
      commit('setUtilisateurs', newValue);
    },
    setQui: ({
      commit
    }, newValue) => {
      commit('setQui', newValue);
    },
    setEtablissement: ({
      commit
    }, newValue) => {
      commit('setEtablissement', newValue);
    },
    setMenu: ({
      commit
    }, newValue) => {
      commit('setMenu', newValue);
    },
    setProfesseurs: ({
      commit
    }, newValue) => {
      commit('setProfesseur', newValue);
    },
    setProfesseursCours: ({
      commit
    }, newValue) => {
      commit('setProfesseursCours', newValue);
    },
    setCours: ({
      commit
    }, newValue) => {
      commit('setCours', newValue);
    },
    setLeCours: ({
      commit
    }, newValue) => {
      commit('setLeCours', newValue);
    },
    setCoursEtablissement: ({
      commit
    }, newValue) => {
      commit('setCoursEtablissement', newValue);
    },
    setCoursSections: ({
      commit
    }, newValue) => {
      commit('setCoursSections', newValue);
    },
    setCoursOptions: ({
      commit
    }, newValue) => {
      commit('setCoursOptions', newValue);
    },
    setCoursProfesseur: ({
      commit
    }, newValue) => {
      commit('setCoursProfesseur', newValue);
    },
    setEmploiDuTemps: ({
      commit
    }, newValue) => {
      commit('setEmploiDuTemps', newValue);
    },
    setHoraire: ({
      commit
    }, newValue) => {
      commit('setHoraire', newValue);
    },
    setDocuments: ({
      commit
    }, newValue) => {
      commit('setDocuments', newValue);
    },
    setEtudiants: ({
      commit
    }, newValue) => {
      commit('setEtudiants', newValue);
    },
    setAppreciations: ({
      commit
    }, newValue) => {
      commit('setAppreciations', newValue);
    },
    setCoursPredefinis: ({
      commit
    }, newValue) => {
      commit('setCoursPredefinis', newValue);
    },
    setSites: ({
      commit
    }, newValue) => {
      commit('setSites', newValue);
    },
    setSections: ({
      commit
    }, newValue) => {
      commit('setSections', newValue);
    },
    setOptions: ({
      commit
    }, newValue) => {
      commit('setOptions', newValue);
    },
    setHeureEtudes: ({
      commit
    }, newValue) => {
      commit('setHeureEtudes', newValue);
    },
    setVacations: ({
      commit
    }, newValue) => {
      commit('setVacations', newValue);
    },
    setClasses: ({
      commit
    }, newValue) => {
      commit('setClasses', newValue);
    },
    setLaClasse: ({
      commit
    }, newValue) => {
      commit('setLaClasse', newValue);
    },
    setDialogError: ({
      commit
    }, newValue) => {
      commit('setDialogError', newValue);
    },
    setLOption: ({
      commit
    }, newValue) => {
      commit('setLOption', newValue);
    },
    setLaSection: ({
      commit
    }, newValue) => {
      commit('setLaSection', newValue);
    },
    setLaSession: ({
      commit
    }, newValue) => {
      commit('setLaSession', newValue);
    },
    setDialogMessage: ({
      commit
    }, newValue) => {
      commit('setDialogMessage', newValue);
    },
    setEnseigner: ({
      commit
    }, newValue) => {
      commit('setEnseigner', newValue);
    },
    setListeCotes: ({
      commit
    }, newValue) => {
      commit('setListeCotes', newValue);
    },
    setListePresence: ({
      commit
    }, newValue) => {
      commit('setListePresence', newValue);
    },
    setIdentifiant: ({
      commit
    }, newValue) => {
      commit('setIdentifiant', newValue);
    },
    setProvince: ({
      commit
    }, newValue) => {
      commit('setProvince', newValue);
    },
    setStats: ({
      commit
    }, newValue) => {
      commit('setStats', newValue);
    },
    setStatsTotal: ({
      commit
    }, newValue) => {
      commit('setStatsTotal', newValue);
    },
    setSearch: ({
      commit
    }, newValue) => {
      commit('setSearch', newValue);
    },
    setDonnee: ({
      commit
    }, newValue) => {
      commit('setDonnee', newValue);
    },

  },
  modules: {},
});