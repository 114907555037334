import {
  createRouter,
  createWebHistory
} from "vue-router";

// import Vue from 'vue';
// import VueRouter from 'vue-router';

// Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "mosi",
    // component: () =>
    //   import("../views/Login.vue")
    // beforeEnter() {
    //   window.location.href = '../../Mosi/index.html';
    // }
  },
  {
    path: "/home",
    name: "home",
    component: () =>
      import("../views/HomeView.vue")
  },
  {
    path: "/getBulletin",
    name: "getBulletin",
    component: () =>
      import("../views/GetBulletin.vue")
  },
  {
    path: "/:id",
    name: "school",
    component: () =>
      import("../views/School.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Login.vue")
  },
  {
    path: "/motdepasseoublie",
    name: "forgot",
    component: () =>
      import( /* webpackChunkName: "about" */ "../views/Forgot.vue")
  },
  {
    path: "/etablissement/dashboard",
    name: "dashboard",
    component: () => import("../components/Users/Etablissements/School.vue"),
  },
  {
    path: "/:id/setup",
    name: "setup-etablissement",
    component: () => import("../components/Users/Etablissements/Setup/Menu.vue"),
  },
  {
    path: "/:id/setup/sections",
    name: "setup-etablissement-sections",
    component: () => import("../components/Users/Etablissements/Setup/Sections.vue"),
  },
  {
    path: "/:id/setup/classes",
    name: "setup-etablissement-classes",
    component: () => import("../components/Users/Etablissements/Setup/Classes.vue"),
  },
  {
    path: "/:id/setup/sites",
    name: "setup-etablissement-sites",
    component: () => import("../components/Users/Etablissements/Setup/Sites.vue"),
  },
  {
    path: "/:id/setup/heures",
    name: "setup-etablissement-heure",
    component: () => import("../components/Users/Etablissements/Setup/Heure.vue"),
  },
  {
    path: "/etablissement/utilisateurs",
    name: "gestionnaire-utilisateurs",
    component: () => import("../components/Users/Etablissements/Includes/Gestionnaire_Utilisateurs.vue"),
  },
  {
    path: "/etablissement/professeurs",
    name: "professeurs",
    component: () => import("../components/Users/Etablissements/Professeurs.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../components/Users/Etablissements/Includes/Profile.vue"),
  },
  {
    path: "/etablissement/communication",
    name: "communication",
    component: () => import("../components/Users/Etablissements/Includes/Communication.vue"),
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../components/Users/Public/Settings.vue"),
  },
  {
    path: "/etablissement/utilisateur/:id",
    name: "details-utilisateur",
    component: () => import("../components/Users/Etablissements/Includes/Utilisateur_Details.vue"),
  },
  {
    path: "/user/identite",
    name: "user-identite",
    component: () => import("../components/Users/Public/Identite.vue"),
  },
  {
    path: "/inscription",
    name: "user-inscription",
    component: () => import("../components/Users/Public/Inscription.vue"),
  },
  {
    path: "/etablissement/user/documents",
    name: "etablissement-user-documents",
    component: () => import("../components/Users/Etablissements/Documents.vue"),
  },
  {
    path: "/user/documents",
    name: "user-documents",
    component: () => import("../components/Users/Public/Documents.vue"),
  },
  {
    path: "/user/parametres",
    name: "user-parametres",
    component: () => import("../components/Users/Public/Settings.vue"),
  },
  {
    path: "/professeur/details",
    name: "professeur-details",
    component: () => import("../components/Users/Professeurs/Schedule.vue"),
  },
  {
    path: "/professeur/:id",
    name: "professeur-menu",
    component: () => import("../components/Users/Professeurs/Menu.vue"),
  },
  {
    path: "/professeur/cours",
    name: "professeur-cours",
    component: () => import("../components/Users/Professeurs/Cours.vue"),
  },
  {
    path: "/professeur/cours/:id/:id",
    name: "professeur-cours-menu",
    component: () => import("../components/Users/Professeurs/CoursMenu.vue"),
  },
  {
    path: "/professeur/cours/:id/:id/liste/resultat",
    name: "professeur-cours-presence-resultat",
    component: () => import("../components/Users/Professeurs/ResultatPresences.vue"),
  },
  {
    path: "/professeur/cours/:id/:id/liste/presence",
    name: "professeur-cours-presence",
    component: () => import("../components/Users/Professeurs/ListePresence.vue"),
  },
  {
    path: "/professeur/cours/:id/:id/liste/cotes/resultat",
    name: "professeur-cours-cotes-resultat",
    component: () => import("../components/Users/Professeurs/ResultatCotes.vue"),
  },
  {
    path: "/professeur/cours/:id/:id/liste/cotes",
    name: "professeur-cours-cotes",
    component: () => import("../components/Users/Professeurs/ListeCotes.vue"),
  },
  {
    path: "/professeur/cours/:id/:id/liste/cotes/etudiants",
    name: "professeur-cours-cotes-etudiants",
    component: () => import("../components/Users/Professeurs/EtudiantsCotes.vue"),
  },
  {
    path: "/professeur/cours/:id/:id/liste/presence/etudiants",
    name: "professeur-cours-presences-etudiants",
    component: () => import("../components/Users/Professeurs/EtudiantsPresences.vue"),
  },
  {
    path: "/professeur/cours/:id/:id/appreciation",
    name: "professeur-cours-liste-appreciation",
    component: () => import("../components/Users/Professeurs/ListeAppreciation.vue"),
  },
  {
    path: "/professeur/cours/:id/:id/appreciation/etudiants",
    name: "professeur-cours-appreciation",
    component: () => import("../components/Users/Professeurs/Appreciation.vue"),
  },
  {
    path: "/professeur/schedule",
    name: "professeur-schedule",
    component: () => import("../components/Users/Professeurs/Schedule.vue"),
  },
  {
    path: "/professeur/horaire",
    name: "professeur-horaire",
    component: () => import("../components/Users/Professeurs/Horaire.vue"),
  },
  {
    path: "/etablissement/etudiants",
    name: "etudiants",
    component: () => import("../components/Users/Etablissements/Etudiants.vue"),
  },
  {
    path: "/etablissement/appreciation",
    name: "etablissement-appreciation",
    component: () => import("../components/Users/Etablissements/Appreciation.vue"),
  },
  {
    path: "/etudiant/menu",
    name: "etudiant-menu",
    component: () => import("../components/Users/Etudiants/Menu.vue"),
  },
  {
    path: "/etudiant/menu-cours",
    name: "etudiant-menu-cours",
    component: () => import("../components/Users/Etudiants/MenuCours.vue"),
  },
  {
    path: "/etudiant/cours",
    name: "etudiant-cours",
    component: () => import("../components/Users/Etudiants/Course.vue"),
  },
  {
    path: "/etudiant/cotes",
    name: "etudiant-cotes",
    component: () => import("../components/Users/Etudiants/Cotes.vue"),
  },
  {
    path: "/etudiant/presence",
    name: "etudiant-presence",
    component: () => import("../components/Users/Etudiants/Presences.vue"),
  },
  {
    path: "/etudiant/horaire",
    name: "etudiant-horaire",
    component: () => import("../components/Users/Etudiants/Horaire.vue"),
  },
  {
    path: "/etudiant/appreciation",
    name: "etudiant-appreciation",
    component: () => import("../components/Users/Etudiants/Appreciation.vue"),
  },
  {
    path: "/etablissement/horaire/menu",
    name: "horaire-menu",
    component: () => import("../components/Users/Etablissements/Horaire/Menu.vue"),
  },
  {
    path: "/etablissement/horaire/setup",
    name: "horaire-setup",
    component: () => import("../components/Users/Etablissements/Horaire/Setup.vue"),
  },
  {
    path: "/etablissement/horaire/setup/room",
    name: "horaire-setup-room",
    component: () => import("../components/Users/Etablissements/Horaire/SetupRoom.vue"),
  },
  {
    path: "/etablissement/horaire/setup/vacation",
    name: "horaire-setup-vacation",
    component: () => import("../components/Users/Etablissements/Horaire/SetupVacation.vue"),
  },
  {
    path: "/etablissement/horaire/heure-etude",
    name: "heure-etude",
    component: () => import("../components/Users/Etablissements/Setup/Heure.vue"),
  },
  {
    path: "/etablissement/horaire/vacation",
    name: "vacation",
    component: () => import("../components/Users/Etablissements/Horaire/Vacation.vue"),
  },
  {
    path: "/etablissement/horaire/setup/avant-horaire",
    name: "avant-horaire",
    component: () => import("../components/Users/Etablissements/Horaire/Avant_Horaire.vue"),
  },
  {
    path: "/etablissement/horaire/setup/classe-avant-horaire",
    name: "classe-avant-horaire",
    component: () => import("../components/Users/Etablissements/Horaire/ClassesAvantHoraire.vue"),
  },
  {
    path: "/etablissement/horaire",
    name: "horaire-classes",
    component: () => import("../components/Users/Etablissements/Horaire/Classes.vue"),
  },
  {
    path: "/etablissement/horaire/:id/:id",
    name: "horaire",
    component: () => import("../components/Users/Etablissements/Horaire/Horaire.vue"),
  },
  {
    path: "/etablissement/cours/professeurs",
    name: "course-professeurs-liste",
    component: () => import("../components/Users/Etablissements/Cours/Professeurs.vue"),
  },
  {
    path: "/etablissement/cours/professeur/:id",
    name: "course-professeurs",
    component: () => import("../components/Users/Etablissements/Cours/CourseProfesseur.vue"),
  },
  {
    path: "/etablissement/cours/liste",
    name: "cours-etablissement",
    component: () => import("../components/Users/Etablissements/Cours/CoursEtablissement.vue"),
  },
  {
    path: "/etablissement/cours/options",
    name: "cours-options",
    component: () => import("../components/Users/Etablissements/Cours/CoursOptions.vue"),
  },
  {
    path: "/etablissement/cours/classes",
    name: "cours-classes",
    component: () => import("../components/Users/Etablissements/Cours/Classes.vue"),
  },
  {
    path: "/etablissement/cours/:id/:id",
    name: "cours-classes-promotion",
    component: () => import("../components/Users/Etablissements/Cours/CoursClasse.vue"),
  },
  {
    path: "/:id/cours/ajout",
    name: "cours-ajout",
    component: () => import("../components/Users/Etablissements/Cours/CoursAjoutes.vue"),
  },
  {
    path: "/cours",
    name: "cours-predefined",
    component: () => import("../components/Users/Etablissements/Cours/CoursPredefined.vue"),
  },
  {
    path: "/cours/:id",
    name: "cours-details",
    component: () => import("../components/Users/Etablissements/Cours/CoursDetails.vue"),
  },
  {
    path: "/etablissement/cours/sections",
    name: "cours-sections",
    component: () => import("../components/Users/Etablissements/Cours/CoursSection.vue"),
  },
  {
    path: "/etablissement/cours",
    name: "cours-menu",
    component: () => import("../components/Users/Etablissements/Cours/Menu.vue"),
  },
  {
    path: "/bulletin",
    name: "bulletin",
    component: () =>
      import("../components/Users/Result/Resultat.vue")
  },
  // {
  //   path: "/inspecteur/statistiques",
  //   name: "map",
  //   component: () =>
  //     import("../components/Users/Inspecteurs/Load.vue")
  // },
  {
    path: "/inspecteur/statistiques",
    name: "inspecteur-menu",
    component: () =>
      import("../components/Users/Inspecteurs/Menu.vue")
  },
  {
    path: "/inspecteur/map",
    name: "inspecteur-dashboard",
    component: () =>
      import("../components/Users/Inspecteurs/Dashboard.vue")
  },
  {
    path: "/inspecteur/etablissement",
    name: "inspecteur-etablissement",
    component: () =>
      import("../components/Users/Inspecteurs/Etablissement.vue")
  },
  {
    path: "/inspecteur/etudiants",
    name: "inspecteur-etudiant",
    component: () =>
      import("../components/Users/Inspecteurs/Etudiant.vue")
  },
  {
    path: "/inspecteur/professeurs",
    name: "inspecteur-professeur",
    component: () =>
      import("../components/Users/Inspecteurs/Professeur.vue")
  },
  {
    path: "/inspecteur/province",
    name: "inspecteur-province",
    component: () =>
      import("../components/Users/Inspecteurs/Province.vue")
  },
  {
    path: "/inspecteur/recherche",
    name: "inspecteur-recherche",
    component: () =>
      import("../components/Users/Inspecteurs/Recherche.vue")
  },
  {
    path: "/inspecteur/recherche/result",
    name: "inspecteur-recherche-result",
    component: () =>
      import("../components/Users/Inspecteurs/ResultatRecherche.vue")
  },
  {
    path: "/inspecteur/comparaison",
    name: "inspecteur-comparaison",
    component: () =>
      import("../components/Users/Inspecteurs/Comparaison.vue")
  },
  {
    path: "/inspecteur/comparaison/result",
    name: "inspecteur-comparaison-result",
    component: () =>
      import("../components/Users/Inspecteurs/ResultatComparaison.vue")
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // mode: 'hash',
  routes
});

// const router = new VueRouter({
//   routes
// });

export default router;